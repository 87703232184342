<template>
  <div class="container" id="processingFormTemplates_edit_template">
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <h1 v-html="$t('processing_form_template_edit_title')"></h1>

    <v-tabs v-model="activeTab" color="primary" class="mb-5">
      <v-tab value="overview" id="tab_kopfdaten"><span v-html="$t('kopfdaten')" /></v-tab>
      <v-tab value="template" id="tab_gesuchvorlage"><span v-html="$t('gesuchvorlage')" /></v-tab>
    </v-tabs>

    <div v-if="activeTab == 'overview'">
      <v-row class="row search">
        <v-col class="item" cols="12" md="4">
          <h2 v-html="$t('milchverwerter')"></h2>
          <v-row>
            <v-text-field
              type="number"
              id="dairyIdent"
              v-model="processingFormTemplate.dairyIdent"
              disabled
              placeholder="dairyIdent"
              :label="$t('processing_form_template_create_dairyMbhid')"
            />
            <v-textarea
              id="address"
              :label="$t('address')"
              v-model="processingFormTemplate.dairyLocalUnitAddressFormattedAddress"
              disabled
              rows="5"
              :placeholder="$t('address')"
            />
          </v-row>
        </v-col>
        <v-col class="item" cols="12" md="4">
          <h2 v-html="$t('gesuchsvorgaben')"></h2>
          <v-form @submit.prevent="">
            <v-row>
              <v-select
                id="formPeriod"
                required
                :items="formPeriods"
                :label="$t('processing_form_template_create_formPeriod')"
                persistent-hint
                item-value="id"
                :item-title="this.$getLangKey()"
                v-model="processingFormTemplate.formPeriodId"
              />
              <DbmMonthPicker
                name="validFromOld"
                v-model="processingFormTemplate.validFromOld"
                :dateLabel="$t('processing_form_template_earliest_create_follow_up_form')"
                :rules="[$rules.required]"
                :readonly="true"
              />
              <DbmMonthPicker
                name="validFrom"
                v-model="processingFormTemplate.validFrom"
                :dateLabel="$t('processing_form_template_create_from')"
                :clearable="true"
              />
            </v-row>
          </v-form>
        </v-col>
        <v-col class="item" cols="12" sm="12" md="4" lg="4">
          <rdhComponent
            :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
            :remarks_show="true"
            :remarks_tag="'processingFormTemplates_' + $route.params.id"
            :documents_show="true"
            :documents_tag="'processingFormTemplates_' + $route.params.id"
            :history_show="true"
            :history_url="'/processingFormTemplates/' + $route.params.id"
            ref="rdh"
          ></rdhComponent>
        </v-col>
      </v-row>
      <v-row>
        <div class=""></div>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-btn id="btn_save" color="primary shadow-none" @click="onSubmit"><span v-html="$t('products_button_save')" /></v-btn>
          <v-btn id="btn_close" color="secondary shadow-none" @click="back"><span v-html="$t('products_button_close')" /></v-btn>
          <v-btn id="btn_create_tsm1" color="secondary" @click="createTSM1"><span v-html="$t('create_manually')" /></v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="activeTab == 'template'">
      <v-form ref="form" lazy-validation @submit.prevent="onRowSubmit()">
        <v-row>
          <v-col sm="4">
            <div class="pin-card">
              <v-text-field
                id="addproduct_fast"
                ref="fastinput"
                v-model="fast"
                type="text"
                placeholder=""
                autocomplete="off"
                required
                :label="$t('processing_form_template_edit_fast') + ' ' + $t('processing_form_template_edit_fast_description')"
                @keypress.enter="onRowSubmit"
              />
              <v-select
                id="addproduct_formParts"
                required
                :rules="rules"
                v-model="formPart.id"
                :items="formParts"
                item-value="id"
                :item-title="this.$getLangKey()"
                :label="$t('processing_form_template_edit_formParts')"
                :hint="$t('processing_form_template_edit_formParts_description')"
                persistent-hint
              />
              <v-select
                id="addproduct_formPartGroups"
                required
                :rules="rules"
                v-model="processingFormTemplateRow.formPartGroup.id"
                :items="filteredformPartGroups"
                item-value="id"
                :item-title="this.$getLangKey()"
                :label="$t('processing_form_template_edit_formPartGroups')"
                :hint="$t('processing_form_template_edit_formPartGroups_description')"
                persistent-hint
              />

              <v-text-field
                id="addproduct_block"
                :rules="rules"
                v-model="processingFormTemplateRow.block"
                :label="$t('processing_form_template_edit_block')"
                type="number"
                required
              />

              <v-select
                id="addproduct_formRowTypes"
                :rules="rules"
                required
                v-model="processingFormTemplateRow.formRowType.id"
                :items="filteredformRowTypes"
                item-value="id"
                :item-title="this.$getLangKey()"
                :label="$t('processing_form_template_edit_formRowTypes')"
              />
              <v-select
                id="addproduct_product"
                :rules="rules"
                required
                v-model="processingFormTemplateRow.product.id"
                :items="product"
                :label="$t('processing_form_template_edit_product')"
                item-value="id"
                :item-title="$getLangKey()"
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <v-list-item-title
                      :id="props['aria-labelledby']"
                      v-title="item[$getLangKey()]"
                      :class="item.validUntil && item.validUntil < currentDateTime ? 'expired-product-dropdown' : undefined"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>

              <v-select
                id="addproduct_milkLabel"
                :rules="rules"
                required
                v-model="processingFormTemplateRow.milkLabel"
                :items="milkLabels"
                :label="$t('processing_form_template_edit_milklabel')"
                item-value="name"
                :item-title="this.$getLangKey()"
              ></v-select>
              <!-- <v-checkbox id="addproduct_label" v-model="processingFormTemplateRow.label" :label="$t('processing_form_template_edit_milklabel')" name="label-1" /> -->

              <div class="aligned-buttons">
                <v-btn id="addproduct_btn_submit" color="primary shadow-none" class="" @click="onRowSubmit">
                  <span v-html="$t('processing_form_template_edit_button_add')" />
                </v-btn>
              </div>

              <div>
                <br />
                <div class="import-title">
                  <span v-html="$t('importieren_von_vorlage')" />
                </div>
                <v-text-field id="import_from_ident" :label="'MBH-ID '" v-model="mbh" type="text" placeholder="" autocomplete="off" />
                <v-btn id="import_from_btn_import" color="primary shadow-none" class="button-margin" @click="importVorlage">
                  <span v-html="$t('import')" />
                </v-btn>
              </div>
              <div>
                <v-btn id="addproduct_btn_back" color="secondary shadow-none" class="button-top-margin" @click="back">
                  <span v-html="$t('processing_form_template_edit_button_close')" />
                </v-btn>
              </div>
            </div>
          </v-col>

          <v-col sm="8">
            <processingFormTemplatePreview
              :processingFormTemplatesId="Number.parseInt($route.params.id)"
              :processingFormTemplatePreview="processingFormTemplatePreview"
              @delete-row="deleteRow"
            ></processingFormTemplatePreview>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>
<script lang="ts">
const processingFormTemplateRowMinimal = {
  processingFormTemplate: {
    id: null
  },
  formPartGroup: {
    id: null
  },
  formRowType: {
    id: null
  },
  product: {
    id: null
    //code: null,
  },
  block: null,
  milkLabel: null
}
import { apiURL, emitter } from '@/main'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import processingFormTemplatePreview from '@/views/mbh/processingFormTemplates/processingFormTemplatePreview.vue'
import { showError } from '@/services/axios'
import { privileges } from '@/services/privileges'

import rdhComponent from '@/components/rdh/rdhComponent'
import { useSettingsStore } from '@/store/SettingsStore'
import { format } from 'date-fns'
import { useFormPeriodsStore } from '@/store/enums/FormPeriodsStore'
import { useMilkLabelsStore } from '@/store/enums/MilkLabelsStore'

const processingFormTemplateMinimal = {
  dairyIdent: 1,
  dairyLocalUnitAddressFormattedAddress: '',
  id: null,
  formPeriodNameDe: '',
  formPeriodNameFr: '',
  formPeriodNameIt: '',
  validFrom: format(new Date(), 'yyyy-MM-01'),
  formPeriodId: 1
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'processingFormTemplate_create',
  props: { id: Number, tab: String },
  data() {
    return {
      fast: '',
      processingFormTemplateRow: processingFormTemplateRowMinimal,
      formPart: {
        id: null
      },
      formPartGroup: { applicationFormPartId: null },
      formParts: [],
      formPartGroups: [],
      formRowTypes: [],
      product: [],
      processingFormTemplatePreview: [],
      mbh: '',
      privileges: privileges,
      processingFormTemplate: processingFormTemplateMinimal,
      rules: [(v) => !!v], // aktiviert errorhandling von v-select
      loading: false,
      menu1: false
    }
  },
  components: {
    processingFormTemplatePreview,
    DbmMonthPicker: DbmMonthPicker,
    rdhComponent: rdhComponent
  },
  computed: {
    formPeriods() {
      return useFormPeriodsStore().formPeriods
    },
    milkLabels() {
      return useMilkLabelsStore().milkLabels
    },
    filteredformRowTypes() {
      return this.formRowTypes.filter((x) => x.formPart.id === this.formPart.id)
    },
    filteredformPartGroups() {
      return this.formPartGroups.filter((x) => x.applicationFormPartId === this.formPart.id)
    },
    currentDateTime() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    activeTab: {
      get() {
        return this.tab
      },
      set(tabChoosen) {
        console.log('tabChoosen', tabChoosen)
        this.$router
          .push({
            name: 'processingFormTemplates_update',
            params: { id: parseInt(this.id), tab: tabChoosen }
          })
          .catch((e) => {
            /* nav duplicated */
          })
      }
    }
  },
  methods: {
    async loadprocessingFormTemplatePreview() {
      try {
        const response3 = await this.axios.get(apiURL + '/processingFormTemplates/' + this.$route.params.id + '/processingFormTemplatePreview', {
          headers: { Accept: 'application/json' }
        })
        const data3 = await response3.data
        this.processingFormTemplatePreview = data3
      } catch (e) {
        showError(e)
      }
    },

    async deleteRow(gRow) {
      if (confirm('delete "' + gRow.productCode + ' ' + gRow['rowType' + this.$getUpLangKey()] + ' ' + gRow['product' + this.$getUpLangKey()] + '" ?')) {
        try {
          gRow.isLoading = true
          const result = await this.axios.delete(
            apiURL + '/processingFormTemplateRows',
            { params: { id: gRow.id } },
            { headers: { 'Content-Type': 'application/json' } }
          )
          if (await result) {
            await this.loadprocessingFormTemplatePreview()
          }
        } catch (e) {
          showError(e)
        }
      } else {
      }
    },

    async onSubmit() {
      if (this.processingFormTemplate.validFrom != null && this.processingFormTemplate.validFrom.length < 10) {
        this.processingFormTemplate.validFrom += '-01'
      }
      const processingFormTemplateToSend = {
        dairy: {
          ident: this.processingFormTemplate.dairyIdent,
          id: this.$route.params.id
        },
        formPeriod: {
          id: this.processingFormTemplate.formPeriodId
        },
        validFrom: this.processingFormTemplate.validFrom,

        formType: {
          id: 1
        },
        copies: 1
      }

      try {
        const result = await this.axios.patch(
          apiURL + '/processingFormTemplates/' + this.$route.params.id,
          { ...processingFormTemplateToSend },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.$router
            .push({
              name: 'processingFormTemplates_update',
              params: { id: parseInt(result.data.id), tab: this.tab }
            })
            .catch((e) => {
              /* nav duplicated */
            })
          emitter.emit('loadHistory')
        }
      } catch (e) {
        showError(e)
      }
    },
    async onRowSubmit() {
      this.$refs.form.validate()
      if (
        !this.processingFormTemplateRow.block ||
        !this.processingFormTemplateRow.formPartGroup.id ||
        !this.processingFormTemplateRow.formRowType.id ||
        !this.processingFormTemplateRow.processingFormTemplate.id ||
        !this.processingFormTemplateRow.product.id
      ) {
        return undefined
      }
      try {
        const result = await this.axios.post(
          apiURL + '/processingFormTemplateRows',
          { ...this.processingFormTemplateRow },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.fast = ''
          this.loadprocessingFormTemplatePreview()
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.$refs.form.reset()
        }
      } catch (e) {
        showError(e)
      }
    },
    async importVorlage() {
      this.loading = true
      try {
        const result = await this.axios.get(
          apiURL + '/processingFormTemplates/' + this.$route.params.id + '/copyRows',
          { params: { dairyIdent: this.mbh } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.mbh = ''
          this.loadprocessingFormTemplatePreview()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async loadKopfdaten() {
      // this.processingFormTemplate.dairy.id = this.$route.query.id;
      // this.processingFormTemplate.dairy.ident = this.$route.query.dairyIdent;

      //kopfdaten
      try {
        const response2 = await this.axios.get(apiURL + '/processingFormTemplates/' + this.$route.params.id, { headers: { Accept: 'application/json' } })
        const data2 = await response2.data
        this.processingFormTemplate = data2
        this.setValidFromDefaultDates()
      } catch (e) {
        showError(e)
      }
    },
    async createTSM1() {
      this.loading = true
      const periodSpanMonthes = this.formPeriods.filter((fp) => fp.id == this.processingFormTemplate.formPeriodId)[0].months
      try {
        const response = await this.axios.get(apiURL + '/processingForms/generateNext', {
          params: {
            processingFormTemplateId: this.$route.params.id,
            startOfPeriod: this.processingFormTemplate.validFrom,
            periodSpan: periodSpanMonthes
          }
        })

        emitter.emit('toastSuccess', {
          message: this.$t('processing_form_template_create_manually_success')
        })
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    submitAndClose() {
      this.onSubmit()
      this.back()
    },
    back() {
      this.$router.go(-2)
    },
    getformPartByIdent(ident) {
      const result = this.formParts.filter((elx) => elx.ident == ident)
      return result.length === 1 ? result[0].id : null
    },
    getformPartGroupsByIdent(ident) {
      const result = this.filteredformPartGroups.filter((elx) => elx.ident == ident)
      return result.length > 0 ? result[0].id : null
    },
    getformRowTypeByIdent(ident) {
      // ident starts with 0
      const result = this.filteredformRowTypes.filter((elx) => elx.ident === Number.parseInt(ident))
      return result.length > 0 ? result[0].id : null
    },
    getProductByCode(ident) {
      // ident starts with 0
      const result = this.product.filter((elx) => elx.code == ident)
      return result.length > 0 ? result[0].id : null
    },
    setValidFromDefaultDates() {
      this.processingFormTemplate.validFromOld = this.processingFormTemplate.validFrom // letzte Erzeugung
      this.processingFormTemplate.validFrom = format(new Date(), 'yyyy-MM-dd') // aktueller Monat
    }
  },
  watch: {
    fast: {
      handler(value) {
        const elements = value.split('.')
        this.formPart.id = this.getformPartByIdent(elements[0])
        this.processingFormTemplateRow.formPartGroup.id = this.getformPartGroupsByIdent(elements[1])
        this.processingFormTemplateRow.block = elements[2]
        this.processingFormTemplateRow.formRowType.id = this.getformRowTypeByIdent(elements[3])
        if (typeof elements[4] != 'undefined') {
          this.processingFormTemplateRow.product.id = this.getProductByCode(elements[4])
        }
        let enumElement = this.milkLabels.filter((e) => e.ident === parseInt(elements[5]))
        this.processingFormTemplateRow.milkLabel = enumElement.length == 1 ? enumElement[0].name : null
      },
      deep: true
    },
    activeTab(tabindex) {
      this.$router.push({
        name: 'processingFormTemplates_update',
        params: { id: parseInt(this.$route.params.id), tab: tabindex }
      })
    }
  },
  beforeMount() {
    useMilkLabelsStore().fetchMilkLabels()
    useFormPeriodsStore().fetchFormPeriods()
    this.loadprocessingFormTemplatePreview()
    this.loadKopfdaten()
  },
  async mounted() {
    //this.tab = (this.$route.params.tab)?this.$route.params.tab:0
    this.$refs.fastinput?.focus()

    this.processingFormTemplateRow.processingFormTemplate.id = this.$route.params.id
    try {
      const response = await this.axios.get(apiURL + '/formParts', {
        headers: { Accept: 'application/json' }
      })
      const data = await response.data
      this.formParts = data
      this.formParts.forEach((element) => {
        element.nameDe = `${element.nameDe} (${element.ident})`
        element.nameFr = `${element.nameFr} (${element.ident})`
        element.nameIt = `${element.nameIt} (${element.ident})`
      })
    } catch (e) {
      showError(e)
    }
    try {
      //http://localhost:8199/api/formPartGroups?domainId=1
      const response2 = await this.axios.get(apiURL + '/formPartGroups', {
        headers: { Accept: 'application/json' }
      })
      const data2 = await response2.data
      this.formPartGroups = data2
      this.formPartGroups.forEach((element) => {
        element.nameDe = `${element.nameDe} (${element.ident})`
        element.nameFr = `${element.nameFr} (${element.ident})`
        element.nameIt = `${element.nameIt} (${element.ident})`
      })
    } catch (e) {
      showError(e)
    }

    try {
      const response4 = await this.axios.get(apiURL + '/formRowTypes', {
        headers: { Accept: 'application/json' }
      })
      const data4 = await response4.data
      this.formRowTypes = data4
      this.formRowTypes.forEach((element) => {
        element.nameDe = `${element.nameDe} (${element.ident})`
        element.nameFr = `${element.nameFr} (${element.ident})`
        element.nameIt = `${element.nameIt} (${element.ident})`
      })
    } catch (e) {
      showError(e)
    }

    try {
      const response5 = await this.axios.get(apiURL + '/products/selectList', {
        /*params: { page: 0, size: 2000 },*/
        headers: { Accept: 'application/json' }
      })
      const data5 = await response5.data
      this.product = data5
      this.product.forEach((element) => {
        element.nameDe = `${element.nameDe} (${element.code})`
        element.nameFr = `${element.nameFr} (${element.code})`
        element.nameIt = `${element.nameIt} (${element.code})`
      })
    } catch (e) {
      showError(e)
    }
    let settingsStore = useSettingsStore()
    if (!settingsStore.getDebug) {
      this.product = this.product.filter((product) => product.validUntil === null)
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-window {
  overflow: visible;
}

.pin-card {
  position: -webkit-sticky;
  position: sticky;
  top: $top-sticky-card;
}

.import-title {
  font-size: $font-md;
  color: $dbm-primary-grey;
  margin-top: $spacer-lg;
}

option:disabled {
  color: lightgray !important;
}

ul > li {
  text-align: left;
}

.card-spacing {
  padding-top: $spacer-xxxs !important;
  padding-bottom: $spacer-xxxs !important;
}
.button-margin {
  margin-right: $spacer-xs !important;
}

.button-top-margin {
  margin-top: $spacer-lg !important;
}

.aligned-buttons {
  display: flex !important;
}

.aligned-button-margin {
  margin-right: calc($spacer-xxs/2) !important;
}

.expired-product-dropdown {
  color: $dbm-light-grey;
}

.loading-dialog {
  background-color: $dbm-overlay-color !important;
}
</style>
