<template>
  <div id="processingFormTemplates_preview">
    <v-table class="v-table v-data-table no-margin-top" density="compact">
      <tbody>
        <template v-for="(formPart, fpindex) of processingFormTemplatePreview" :key="'fp' + fpindex">
          <tr class="formPart">
            <td colspan="2">
              {{ formPart.ident }}
            </td>
            <td colspan="3">
              {{ formPart[this.$getLangKey()] }}
            </td>
          </tr>
          <template v-for="(fpgroup, fpgindex) of formPart.groups" :key="'fp' + fpindex + 'fpg' + fpgindex">
            <tr class="fpgroup">
              <td colspan="2">{{ fpgroup.formPartIdent }}/{{ fpgroup.ident }}/{{ fpgroup.block }}</td>
              <td colspan="2">
                {{ fpgroup[this.$getLangKey()] }}
              </td>
              <td colspan="4">
                <span v-html="$t('processing_form_template_preview_milklabel')" />
              </td>
            </tr>
            <template v-for="(gRow, gindx) of fpgroup.rows" :key="'fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + gindx">
              <tr class="gRow" :id="'product_row_code_' + gRow.code">
                <td :id="'product_row_code_' + gRow.code + '_rowTypeIdent'" :e2e="gRow.rowTypeIdent">
                  {{ gRow.rowTypeIdent }}
                </td>
                <td :id="'product_row_code_' + gRow.code + '_productCode'" :e2e="gRow.productCode">
                  {{ gRow.productCode }}
                </td>
                <td :id="'product_row_code_' + gRow.code + '_rowTypeName'" :e2e="gRow['rowType' + this.$getUpLangKey()]">
                  {{ gRow['rowType' + this.$getUpLangKey()] }}
                </td>
                <td colspan="1" :id="'product_row_code_' + gRow.code + '_productName'" :e2e="gRow['product' + this.$getUpLangKey()]">
                  <p :class="gRow.productValidUntil ? 'expired-product' : undefined">
                    {{ gRow['product' + this.$getUpLangKey()] }}<span v-if="gRow.productValidUntil"> ({{ $formatCHDate(gRow.productValidUntil) }})</span>
                  </p>
                </td>
                <td :id="'product_row_code_' + gRow.code + '_milklabel'" :e2e="gRow.milkLabel">
                  {{ translateMilkLabel(gRow.milkLabel) }}
                  <!--  {{ milkLabels.filter((e) => e.name == grow.milkLabel)[0][this.$getLangKey()] }}-->
                </td>
                <td>
                  <v-btn
                    :loading="gRow.isLoading === true"
                    :disabled="gRow.isLoading === true"
                    :id="'product_row_code_' + gRow.code + '_delete'"
                    @click="$emit('delete-row', gRow)"
                    variant="text"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </v-table>
  </div>
</template>
<script lang="ts">
import { useMilkLabelsStore } from '@/store/enums/MilkLabelsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'processingFormTemplatePreview',
  props: {
    processingFormTemplatesId: Number,
    processingFormTemplatePreview: Array
  },
  emits: ['delete-row'],

  computed: {
    milkLabels() {
      return useMilkLabelsStore().milkLabels
    }
  },
  methods: {
    translateMilkLabel(labelName) {
      let labels = this.milkLabels.filter((e) => e.name == labelName)
      return Array.isArray(labels) && labels.length ? labels[0][this.$getLangKey()] : 'none'
    },
    beforeMount() {
      useMilkLabelsStore().fetchMilkLabels()
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/colors.scss';
@import '@/styles/variables.scss';
.table {
  width: 100%;
}
.delete-row:hover {
  cursor: pointer;
}

.expired-product {
  color: $dbm-passiv-grey;
}
</style>
